<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="!ready" />
        <div v-if="ready">
          <crud-list
            ref="crudListCadastro"
            v-model="cadastros"
            :headers="headers"
            :actions="actions"
            :slots="[
              'item.statusText',
              'item.messages',
              'item.created_at',
              'item.data_nascimento',
              'item.politica_privacidade',
              'item.termo_adesao'
            ]"
            :permission="'list_solicitacao_cadastro'"
            :sort-by="['status', 'created_at']"
            :sort-desc="[false, true]"
            export-pdf-xlsx
          >
            <template v-slot:[`item.statusText`]="{ item }">
              <v-select
                v-model="item.status"
                :items="listSelect(item)"
                dense
                rounded
                flat
                full-width
                hide-details
                hide-selected
                append-icon=""
                @change="handleFilterInput(item, $event)"
              >
                <template v-slot:selection="{ }">
                  <v-chip
                    :color="RegisterStatusColor[item.status]"
                    outlined
                  >
                    <span>{{ item.statusText }}</span>
                  </v-chip>
                </template>

                <template #item="{ item }">
                  <v-chip
                    :color="RegisterStatusColor[item]"
                    outlined
                  >
                    {{ RegisterStatusText[item] }}
                  </v-chip>
                </template>
              </v-select>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              <span>{{ moment( item.created_at ).format( "DD/MM/YYYY - HH:mm:ss") }}</span>
            </template>
            <template v-slot:[`item.data_nascimento`]="{ item }">
              <span>{{ item.data_nascimento ? moment( item.data_nascimento ).format( "DD/MM/YYYY") : '' }}</span>
            </template>
            <template v-slot:[`item.messages`]="{ item }">
              <div v-if="(item.status == RegisterStatus.APROVADO)">
                <v-tooltip
                  v-if="item.messagesCode == 'DELIVERED'"
                  :key="item.id"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      v-bind="attrs"
                      color="green"
                      v-on="on"
                    >
                      mdi-bell
                    </v-icon>
                  </template>
                  <span>Mensagem entregue!</span>
                </v-tooltip>

                <v-tooltip
                  v-else-if="item.messagesCode == 'SENT'"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      v-bind="attrs"
                      color="blue"
                      v-on="on"
                    >
                      mdi-bell-off
                    </v-icon>
                  </template>
                  <span>Mensagem enviada!</span>
                </v-tooltip>

                <v-tooltip
                  v-else
                  bottom
                  close-delay="1000"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      v-on="on"
                      @click="SEND_SMS(item)"
                    >
                      <v-icon color="red">
                        mdi-bell-off
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Mensagem não enviada ou não entregue! Clique para enviar mensagem.
                  </span>
                </v-tooltip>
              </div>
              <div v-else>
                <v-tooltip
                  :key="item.id"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      small
                      color="gray"
                      v-on="on"
                    >
                      mdi-bell-off
                    </v-icon>
                  </template>
                  <span>
                    Cadastro não aprovado, não é possível enviar mensagem.
                  </span>
                </v-tooltip>
              </div>
            </template>

            <template v-slot:[`item.politica_privacidade`]="{ item }">
              <v-icon v-if="item.politica_privacidade === 1">
                mdi-check
              </v-icon>
              <v-icon v-else>
                mdi-check-box-multiple-outline
              </v-icon>
            </template>

            <template v-slot:[`item.termo_adesao`]="{ item }">
              <v-icon v-if="item.termo_adesao === 1">
                mdi-check
              </v-icon>
              <v-icon v-else>
                mdi-close
              </v-icon>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import store from '@/store'
  import CadastrosStore, { BOOTSTRAP, DELETE_CADASTRO, SEND_SMS, UPDATE_STATUS } from '@/store/modules/cadastros'
  import UserProfileStore, { LOAD_NOTIFICATIONS } from '@/store/modules/userProfile'
  import { mapActions, mapState } from 'vuex'
  import { RegisterStatus, RegisterStatusColor, RegisterStatusText } from '../../utils/cadastro'

  export default {
    props: {
      status: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        RegisterStatusColor,
        RegisterStatus,
        RegisterStatusText,
        search: '',
        actions: [
          {
            title: 'Excluir Cadastro',
            color: 'red darken-3',
            click: item => this.DELETE_CADASTRO(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_solicitacao_cadastro',
          },
        ].filter(action => store.getters.hasPermission(action.permission)),
        headers: [
          { text: '', value: 'actions', sortable: false, groupable: false, width: '20px' },
          { align: 'center', sortable: true, groupable: false, text: 'Notifcação', value: 'messages', width: '30px', report: false },
          { align: 'center', sortable: true, groupable: false, text: 'Status', value: 'statusText', width: '30px', floatingfilter: true, report: true },
          { align: 'center', sortable: true, groupable: false, text: 'Data da Solicitação', value: 'created_at', width: '40px', report: true, styleXLSX: { type: 'date' } },
          { align: 'left', sortable: true, groupable: false, text: 'CPF/CNPJ', value: 'cpf_cnpj', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: false, text: 'Nome', value: 'nome', width: 'auto', report: true },
          { align: 'center', sortable: true, groupable: false, text: 'Data Nascimento', value: 'data_nascimento', width: '40px', report: true, styleXLSX: { type: 'date' } },
          { align: 'left', sortable: true, groupable: false, text: 'Email', value: 'email', width: 'auto', report: true },
          { align: 'left', sortable: false, groupable: false, text: 'Celular/WhatsApp', value: 'celular', width: 'auto', report: true },
          { align: 'left', sortable: false, groupable: false, text: 'Telefone', value: 'telefone', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: true, text: 'Cidade', value: 'cidade', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: true, text: 'Estado', value: 'estado', width: 'auto', floatingfilter: true, report: true },
          { align: 'center', sortable: true, groupable: false, text: 'Politica de privacidade', value: 'politica_privacidade', width: 'auto', floatingfilter: false, report: true },
          { align: 'center', sortable: true, groupable: false, text: 'Termo de uso/adesão', value: 'termo_adesao', width: 'auto', floatingfilter: false, report: true },
        ],

        items: Object.values(RegisterStatusText),
        registerStatusTextReverse: Object.keys(RegisterStatusText).reduce((acc, key) => {
          acc[RegisterStatusText[key]] = key
          return acc
        }, {}),
      }
    },
    asyncComputed: {
      estados: {
        get () {
          return this.api.estados(1).then(estados => estados)
        },
        default: [],
      },
    },
    computed: {
      ...mapState('cadastros', ['ready', 'cadastros']),
      numFilters () {
        return this.filterOpts.filter(v => v.checked && v.value).length
      },
    },
    updated () {
      if (this.status !== '') {
        this.filterStatus(this.status)
      }
    },
    async created () {
      if (!this.$store.hasModule('cadastros')) { this.$store.registerModule('cadastros', CadastrosStore) }
      if (!this.$store.state.userProfile) { this.$store.registerModule('userProfile', UserProfileStore) }
      this.BOOTSTRAP()
    },
    destroyed () {
      this.$store.dispatch('userProfile/LOAD_NOTIFICATIONS')
    },
    methods: {
      ...mapActions('cadastros', [BOOTSTRAP, DELETE_CADASTRO, UPDATE_STATUS, SEND_SMS]),
      ...mapActions('userProfile', [LOAD_NOTIFICATIONS]),
      filterStatus (status) {
        const statusList = status.split(',').map(s => s.trim())

        // Itera sobre cada valor na lista
        statusList.forEach(singleStatus => {
          const status_f = this.$refs.crudListCadastro.filters.statusText.indexOf(singleStatus)

          if (this.$refs.crudListCadastro.filters.statusText && status_f === -1) {
            this.$refs.crudListCadastro.filters.statusText.push(singleStatus)
          } else {
            this.$refs.crudListCadastro.filters.statusText.splice(status_f, 1)
          }
        })
      },

      handleFilterInput (register, status) {
        this.UPDATE_STATUS({
          id: register.id,
          status,
        })
        this.BOOTSTRAP()
      },

      listSelect (register) {
        if (register.status === RegisterStatus.AGUARDANDO_ANALISE || register.status === RegisterStatus.APROVADO || (register.status === RegisterStatus.REPROVADO && register.produtor_id)) {
          return [
            RegisterStatus.AGUARDANDO_ANALISE,
            RegisterStatus.APROVADO,
            RegisterStatus.REPROVADO,
          ]
        }

        return [
          RegisterStatus.AGUARDANDO_CADASTRO,
          RegisterStatus.REPROVADO,
        ]
      },
    },
  }
</script>import { event } from 'jquery'
